import React, { useEffect, useState } from "react";
import { Box, Typography, Button, useTheme, Stack, Card, Grid2 as Grid } from "@mui/material";
import { colors } from "../theme";
import TestimonialCard from "../Components/TestimonialCard";
import BlurryCircle from "../Components/BlurryCircle";
import NoiseSvg from "../Components/NoiseSvg";

import codersConnectLogo from '../assets/logos/CC_Logo_Dark.png';
import AboutTwoDot from "./AboutTwoDot";
import OurBackers from "./OurBackers";

const CaseStudy = ({ sectionRef }) => {
    const theme = useTheme();

    const [positions, setPositions] = useState([
        { top: '28%', left: '-19%' },
        { top: '-8%', left: '-4%' },
        { top: '38%', left: '-58%' },
        { top: '60%', left: '57%' }
    ]);

    useEffect(() => {
        console.log(positions);
    }, [positions]);

    function randomisePositions() {
        let newPositions = Array.from({ length: 4 }, () => ({
            top: `${Math.floor(Math.random() * 151) - 50}%`,
            left: `${Math.floor(Math.random() * 121) - 60}%`
        }));

        setPositions(newPositions);
    }

    const cardStyling = {
        borderRadius: 8,
        background: 'white',
        width: '100%',
        padding: 8,
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: "2px 2px #3C3C3B44"
    }

    return (
        <Box
            ref={sectionRef}
            sx={{
                minHeight: "100vh",
                // margin: "0 10em",
                // backgroundColor: "white",
                // fontFamily: "Recoleta, sans-serif",
                position: "relative",
                overflow: 'hidden',
                "::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    opacity: 0.4,
                    background: theme.palette.primary.main,
                    filter: "url(#noiseFilter)",
                },
            }}
            onClick={() => randomisePositions()}
        >
            <Stack
                paddingX={{ xs: 2, sm: 4, md: 16 }} paddingY={8}
                sx={{
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                spacing={4}
            >

				<AboutTwoDot />
				<OurBackers />

                {/* <Typography variant="h2" textAlign={"center"} color='white'>
                    Case Study from one of our customers
                </Typography>
                <a href="https://codersconnect.co.uk/" target="_blank" rel="noreferrer">
                    <img src={codersConnectLogo} alt="Coders Connect logo" />
                </a>
                <Grid container spacing={2} sx={{ width: "100%" }}>
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                        <Card
                            sx={cardStyling}
                        >
                            <Typography variant="h4">Challenge</Typography>
                            <Typography variant="body1">
                                Coders Connect, a specialist recruitment agency for global tech startups, encountered significant hurdles in scaling their business development (BD) operations. While excelling in connecting talent with cutting-edge roles across Software Engineering, AI, and Data Science, their BD efforts faced critical challenges:

                                Inefficient Lead Targeting: Wasting valuable hours on cold outreach to companies with little hiring potential.

                                Limited Market Intelligence: Insufficient visibility into which companies were actively hiring or expanding.

                                Disconnected BD Processes: Overlapping communications between recruiters, leading to inefficiencies and potential client friction.

                                With an ambition to cement their position as a go-to tech recruitment partner, Coders Connect needed a solution to optimize their BD strategy and eliminate inefficiencies.
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                        <Card
                            sx={cardStyling}
                        >
                            <Typography variant="h4">Solution</Typography>
                            <Typography variant="body1">
                                With Two Dot,
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4 }}>
                        <Card
                            sx={cardStyling}
                        >
                            <Typography variant="h4">Results</Typography>
                            <Typography variant="body1">
                                Coders Connect were able to identify companies on the edge of their network that were actively recruiting. By leveraging connections that they
                                had already established in the industry, we were able to provide them with warm intros to 40 new clients, 15 of which chose to recruit with Coders Connect.
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
                <Stack direction={"row"} spacing={4}>



                </Stack> */}
            </Stack>

            <NoiseSvg />
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <BlurryCircle color={colors.blue} posX={positions[0].left} posY={positions[0].top} />
                <BlurryCircle color={colors.teal} posX={positions[1].left} posY={positions[1].top} />
                <BlurryCircle color={colors.yellow} posX={positions[2].left} posY={positions[2].top} />
                <BlurryCircle color={colors.orange} posX={positions[3].left} posY={positions[3].top} />
            </Box>
        </Box>
    );
};

export default CaseStudy;
