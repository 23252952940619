import { Box, Card, Grid2 as Grid, Stack, Typography, useTheme } from "@mui/material";

const AboutTwoDot = ({ sectionRef }) => {
    const theme = useTheme();

    return (
        <Stack spacing={4} paddingX={{ xs: 2, sm: 4, md: 16 }} paddingTop={8} marginBottom={{ xs: 0, sm: 0, md: 4, lg: 8 }} sx={{ alignItems: 'center' }} ref={sectionRef}>
            {/* <Typography variant="h2" textAlign={"center"}>Two Dot was founded in 2024 by British Computer Scientist <Typography variant="h2" component="span" color={theme.palette.primary.main}>Harry O'Brien</Typography></Typography> */}
            <Typography variant="subtitle1" textAlign={"center"} color="white">
                Founded in 2024, Two Dot was built on a simple yet powerful belief: <br />
                <Typography variant="h2" component="span" color={theme.palette.primary.main}>
                    meaningful connections fuel successful recruitment
                </Typography>
            </Typography>

            <Typography variant="body1" align="justify" width={{ sm: "100%", md: "80%" }}>
                Our platform doesn't just streamline recruitment, it empowers you to <Typography variant="body1" component="span" color={theme.palette.primary.main}>build stronger networks</Typography>,
                faster. With tools designed to amplify your reach,
                uncover hidden talent, and foster meaningful relationships, we help recruiters thrive in even the most competitive industries.
            </Typography>
            <Typography variant="body1" align="justify" width={{ sm: "100%", md: "80%" }}>
                At Two Dot, we combine deep industry insights with a data-driven approach to help you <Typography variant="body1" component="span" color={theme.palette.primary.main}>anticipate needs</Typography>, <Typography variant="body1" component="span" color={theme.palette.primary.main}>nurture relationships</Typography>, and <Typography variant="body1" component="span" color={theme.palette.primary.main}>create lasting success</Typography> for both your agency and your clients.
                With Two Dot, you're not just staying competitive; you're strengthening your brand as an industry leader.
            </Typography>
        </Stack >
    )
}

export default AboutTwoDot;

