import { Box, Stack, Typography } from "@mui/material"
import Grid from '@mui/material/Grid2';
import WaitlistForm from '../Components/WaitlistForm';
import theme from "../theme";

import networkImg from '../assets/supporting/network-wide.png'
import idealCustomerImg from '../assets/supporting/ideal-customer.png'
import customerKnowledgeImg from '../assets/supporting/customerKnowledge.png'

const ValueProposition = ({ sectionRef }) => {


    // <Typography variant="h1">Win More Clients with Data-Driven Insights</Typography>
    // <Typography variant="subtitle1">Uncover companies hiring in your niche and grow your revenue with our business development platform built for recruiters.</Typography>
    // <Typography variant="body1">
    //     Our platform simplifies your business development by instantly identifying companies that actively hiring or likely to hire in your niche. By considering your network and how their
    //     mission aligns with your expertise, you get access to actionable insights, warm introductions, and hyper-personalised outreach.
    // </Typography>
    // <Typography variant="body1">
    //     Build stronger client relationships and close deals faster—all while maximising your revenue potential.
    // </Typography> 

    const listStyle = {
        listStylePosition: 'inside',
        listStyleType: 'disc',
        // fontSize: '1.3rem',
        fontWeight: '300',
        fontFamily: 'Prompt',
        lineHeight: 'normal',
    }

    const imgStyle = {
        width: '100%',
        maxWidth: '1000px',
        height: 'auto',
        objectFit: 'contain',
    };

    const gridTextItemSizing = { md: 12, lg: 5, xl: 5 };
    const gridImgItemSizing = { md: 12, lg: 7, xl: 7 };

    return (
        <Grid paddingX={{ xs: 2, sm: 4, md: 16 }} paddingY={8} ref={sectionRef} container columnSpacing={4} rowSpacing={8}>
            <Grid size={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h2" textAlign={'center'} marginY={4} width={{ sm: '100%', md: '70%' }} color={theme.palette.primary.main}>
                    Unlock the power of your recruiters' networks

                    {/* 
                    Unlock your recruiters potential
                    Your agency's growth engine
                    Intelligence that drives business growth
                    See the opportunites that others miss
                    Know more. Win more.
                */}

                </Typography>
            </Grid>

            {/* Section 1 */}
            <Grid size={gridTextItemSizing}>
                <Stack spacing={2}>
                    <Typography variant="h3">
                        See from a glance the companies that are likely to hire with you.
                    </Typography>
                    <Typography variant="body1">
                        We use a data-driven approach to present you with companies that are likely to recruit via your agency.
                    </Typography>
                    <Typography variant="body1">
                        This means that you can save hours of working cold-messaging irrelevant leads or annoying your previous clients. We repeatedly give you the 'lucky breaks' in your BD.
                    </Typography>
                </Stack>
            </Grid>
            <Grid size={gridImgItemSizing} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={idealCustomerImg} alt="" style={imgStyle} />
            </Grid>

            {/* Section 2 */}

            <Grid size={gridTextItemSizing}>
                <Stack spacing={2}>
                    {/* <Typography variant="h2">
                        
                    </Typography> */}
                    {/* <Typography variant="h2">
                        
                    </Typography> */}
                    <Typography variant="h3">
                        {/* Understand your agency's verticals and strengthen your value proposition to clients. */}
                        {/* Understand your different verticals and strengthen your agency's network. */}
                        {/* Grow your client base and strengthen your agency's network. */}
                        Tactically build relationships with companies in your verticals.
                        {/* Spend  */}
                    </Typography>
                    <Typography variant="body1">
                        We analyse your recruiter's professional networks to identify key companies, candidates and opportunities. Reach the right people,
                        build stronger relationship, and gain more referrals - driving measurable growth for your agency.
                    </Typography>
                </Stack>
            </Grid>
            <Grid size={gridImgItemSizing} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={networkImg} alt="" style={imgStyle} />
            </Grid>


            {/* Section 3 */}
            <Grid size={gridTextItemSizing}>
                <Stack spacing={2}>
                    <Typography variant="h3">
                        {/* Instantly perform your due-diligence before meeting a client */}
                        Know everything about what your customers need.
                    </Typography>
                    <Typography variant="body1">
                        We already know the import information about your clients:
                    </Typography>
                    {/* 
                        <ul style={listStyle}>
                            <li><Typography variant="body1">The roles they are hiring for</Typography></li>
                            <li>Team composition, including recent hires & layoffs</li>
                            <li>Key hiring stakeholders</li>
                            <li>Product information</li>
                            <li>Recent financial raises</li>
                            <li>Potential skill-gaps in their current team</li>
                            <li>Customer base</li>
                            <li>Your agency's previous interactions</li>
                            <li>Any 2nd-degree connections</li>
                            <li>Related companies and competitors</li>
                            <li>And more...</li>
                        </ul>
                    */}
                    <Stack sx={{ paddingLeft: 4 }}>
                        <Typography variant="body1">+ The roles they are hiring for</Typography>
                        <Typography variant="body1">+ Team composition, including recent hires & layoffs</Typography>
                        <Typography variant="body1">+ Key hiring stakeholders</Typography>
                        <Typography variant="body1">+ Product information</Typography>
                        <Typography variant="body1">+ Recent financial raises</Typography>
                        <Typography variant="body1">+ Potential skill-gaps in their current team</Typography>
                        <Typography variant="body1">+ Customer base</Typography>
                        <Typography variant="body1">+ Your agency's previous interactions</Typography>
                        <Typography variant="body1">+ Any 2nd-degree connections</Typography>
                        <Typography variant="body1">+ Related companies and competitors</Typography>
                        <Typography variant="body1">+ And more...</Typography>
                    </Stack>
                    <Typography variant="body1">
                        So you have all of the information necessary to be well informed about your clients' hiring needs.
                    </Typography>
                </Stack>
            </Grid>
            <Grid size={gridImgItemSizing} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={customerKnowledgeImg} alt="" style={imgStyle} />
            </Grid>
        </Grid>
    )
}

export default ValueProposition;