import { useRef, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import NavBar from './Components/NavBar';
import HeroSection from './sections/HeroSection.js';
import ValueProposition from './sections/ValueProposition.js';
import CaseStudy from './sections/CaseStudy.js';
import AboutTwoDot from './sections/AboutTwoDot.js';
import Footer from './sections/Footer.js';
import SignupSection from './sections/SignupSection.js'
import FullFeatures from './sections/FullFeatures.js';
import OurBackers from './sections/OurBackers.js';
import SignupSectionBottom from './sections/SignupSectionBottom.js';
import { Divider, Stack } from '@mui/material';
import DemoBookDialog from './Components/DemoBookDialog.js';

function App() {
	const heroRef = useRef(null);
	const valuePropRef = useRef(null);
	const topSignupSectionRef = useRef(null);
	const fullFeaturesRef = useRef(null);
	const caseStudyRef = useRef(null);
	const aboutTDRef = useRef(null);
	const backersRef = useRef(null);
	const signupBottomRef = useRef(null);
	const footerRef = useRef(null);

	const [logoColour, setLogoColour] = useState("pink");

	const [bookingDialogOpen, setBookingDialogOpen] = useState(false);

	// Detect when the element is in view
	useEffect(() => {
		const handleScroll = () => {
			const refs = [
				{ ref: heroRef, colour: "clear" },
				{ ref: valuePropRef, colour: "pink" },
				{ ref: topSignupSectionRef, colour: "white" },
				{ ref: fullFeaturesRef, colour: "pink" },
				{ ref: caseStudyRef, colour: "white" },
				{ ref: aboutTDRef, colour: "pink" },
				{ ref: backersRef, colour: "pink" },
				{ ref: signupBottomRef, colour: "pink" },
				{ ref: footerRef, colour: "white" },
			];

			// Iterate through refs to find the one currently passing the top
			let colorToSet = "pink"; // Default to clear
			for (const { ref, colour } of refs) {
				if (ref.current) {
					const rect = ref.current.getBoundingClientRect();
					if (rect.top <= 64 && rect.bottom > 64) {
						colorToSet = colour;
						break;
					}
				}
			}
			setLogoColour(colorToSet);
		};

		window.addEventListener('scroll', handleScroll);
		handleScroll(); // Trigger initial scroll detection
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<NavBar
				logoColour={logoColour}
				scrollTargetRef={heroRef}
				openBookingDialog={() => setBookingDialogOpen(true)}
			/>

			<DemoBookDialog
				isOpen={bookingDialogOpen}
				closeDialog={() => setBookingDialogOpen(false)}
			/>
			<Stack>
				<HeroSection scrollTargetRef={valuePropRef} sectionRef={heroRef} />
				<Divider variant='middle' />
				<ValueProposition sectionRef={valuePropRef} />
				<SignupSection sectionRef={topSignupSectionRef} openBookingDialog={() => setBookingDialogOpen(true)} />
				<FullFeatures sectionRef={fullFeaturesRef}/>
				<Divider variant='middle'/>
				<CaseStudy sectionRef={caseStudyRef} />
				
				{/* <AboutTwoDot sectionRef={aboutTDRef} />
				<OurBackers sectionRef={backersRef}/> */}

				<Divider variant='middle'/>
				<SignupSectionBottom sectionRef={signupBottomRef} openBookingDialog={() => setBookingDialogOpen(true)}/>
				<Footer sectionRef={footerRef} pageTopRef={heroRef} />
			</Stack>
		</ThemeProvider>
	);
}

export default App;
