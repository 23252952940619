import { useTheme } from "@emotion/react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    styled,
    useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const DemoBookDialog = ({ isOpen, closeDialog }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        closeDialog();
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            fullScreen={fullScreen}
            maxWidth="lg" // Set maximum width of the dialog
            fullWidth // Ensures the dialog uses full width within the maxWidth constraint
            PaperProps={{
                style: {
                    width: "90%", // Sets the width of the dialog to 90% of the screen width
                    maxWidth: "none", // Overrides the default maxWidth behavior
                },
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Request a demo
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <iframe
                    src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2-sEsHitlYIjRFQjR8vCG-cwOMCcUsY3AwH9IGK26usK0JwDO_RXj8bNbtTisATYLRoQOsxZSL?gv=true"
                    style={{ border: 0, width: "100%", minHeight: '600px', height: "100%" }}
                ></iframe>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default DemoBookDialog;
