
import { createTheme } from '@mui/material/styles';
import { createBreakpoints } from "@mui/system";


import '@fontsource/prompt/800.css';
import '@fontsource/prompt/600.css';
import '@fontsource/prompt/500.css';
import '@fontsource/prompt/400.css';
import '@fontsource/prompt/300.css';

export const colors = {
	white: '#fff',
	black: '#000',
	// grey: '#F5F4F4',
	grey: '#3C3C3B',
	pink: '#FF5375',
	blue: '#03CBFF',
	teal: '#2DC2B6',
	yellow: '#FFD248',
	orange: '#FF9034',
};

const breakpoints = createBreakpoints({});

const theme = createTheme({
	palette: {
		primary: {
			main: colors.pink,
			light: '#FF859D',
		},
		secondary: {
			main: colors.blue,
		},
		blue: {
			main: colors.blue,
		},
		teal: {
			main: colors.teal,
		},
		yellow: {
			main: colors.yellow,
		},
		orange: {
			main: colors.orange,
		},
		white: {
			main: colors.white,
		},
		grey: {
			main: colors.grey,
		},
	},
	typography: {
		fontFamily: 'Prompt, Arial, sans-serif',
		h1: {
			color: colors.pink,
			fontWeight: 800, // Extra Bold
			fontSize: '16rem',
			[breakpoints.down('md')]: {
				fontSize: '11rem',
			},
			[breakpoints.down('sm')]: {
				fontSize: '8rem',
			},
		},
		h2: {
			color: colors.black,
			fontWeight: 800, // Extra Bold
			fontSize: '5rem',
			[breakpoints.down('md')]: {
				fontSize: '4rem',
			},
			[breakpoints.down('sm')]: {
				fontSize: '2.5rem',
			},
		},
		h3: {
			color: colors.black,
			fontWeight: 600, // Extra Bold
			fontSize: '3.5rem',
			[breakpoints.down('md')]: {
				fontSize: '2rem',
			},
			[breakpoints.down('sm')]: {
				fontSize: '2rem',
			},
		},
		h4: {
			color: colors.black,
			fontWeight: 500, // Extra Bold
		},
		h5: {
			color: colors.black,
			fontWeight: 400, // Extra Bold
			fontSize: '1.3rem',
			letterSpacing: '0.05em',
		},
		subtitle1: {
			// color: colors.grey,
			fontWeight: 500, // Extra Bold
			fontSize: '1.3rem',
			[breakpoints.down('md')]: {
				fontSize: '1.1rem', // Font size for small screens (e.g., phones)
			},
		},
		subtitle2: {
			color: colors.black,
			fontWeight: 300, // Extra Bold
		},
		body1: {
			color: colors.black,
			fontWeight: 300, // Light
			fontSize: '1.3rem',

			[breakpoints.down('md')]: {
				fontSize: '1.2rem',
			},
			[breakpoints.down('sm')]: {
				fontSize: '1rem',
			},
		},
		body2: {
			color: colors.grey,
			fontWeight: 300, // Light
			fontSize: '1.0rem',

			[breakpoints.down('md')]: {
				fontSize: '0.9rem',
			},
			[breakpoints.down('sm')]: {
				fontSize: '0.8rem',
			},
		},
		button: {
			color: colors.white,
			fontWeight: 300,
		}
	},
});

export default theme;