import { Button, Stack, Typography, useTheme } from "@mui/material";

const SignupSectionBottom = ({ sectionRef, openBookingDialog }) => {
    const theme = useTheme();

    return (
        <Stack
            ref={sectionRef}
            sx={{
                minHeight: '80vh',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}
            paddingX={{ xs: 2, sm: 4, md: 16 }}
            paddingY={8}
        >
            {/* <Box sx={{alignItems: 'center', textAlign: 'center'}}> */}
            <Stack spacing={{xs: 2, sm: 4, md: 6, lg: 8}} sx={{width: '100%', textAlign: 'center'}}>
                <Stack direction={{ lg: 'column', xl: 'row' }} spacing={{lg: 0, xl: 8}} sx={{justifyContent: 'center', textAlign: 'center'}}>
                    <Typography variant="h1">Know</Typography>
                    <Typography variant="h1">more</Typography>
                </Stack>
                <Stack direction={{ lg: 'column', xl: 'row' }} spacing={{lg: 0, xl: 8}} sx={{justifyContent: 'center'}}>
                    <Typography variant="h1">Win</Typography>
                    <Typography variant="h1">more</Typography>
                </Stack>
            </Stack>

            <Button color='white' variant="contained" size='large' onClick={openBookingDialog}>Request a demo now</Button>
        </Stack>
    )
}

export default SignupSectionBottom;