import { Box } from "@mui/material";

const BlurryCircle = ({ color, posX, posY }) => {

    return (
        <Box
            sx={{
                backgroundColor: color,
                position: "absolute",
                left: posX,
                top: posY,
                height: "80vh",
                width: "80vh",
                borderRadius: "50%",
                filter: "blur(20vh)",
                opacity: 0.9,
            }}
        />
    )
}

export default BlurryCircle;