import { Box, Card, Divider, Grid2 as Grid, Stack, Typography, useTheme } from "@mui/material";
import { cloneElement } from "react";

import { AccountTree } from "@mui/icons-material";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InsightsIcon from '@mui/icons-material/Insights';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ShareIcon from '@mui/icons-material/Share';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import Groups2Icon from '@mui/icons-material/Groups2';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const FullFeatures = ({ sectionRef }) => {
    const theme = useTheme();

    const features = [

        {
            icon: <Groups2Icon />,
            title: "Identify team structure & skillsets",
            body: "Identify the types of engineer and the hiring managers of each team at a given company, removing the guesswork from cold contacting."
        },
        {
            icon: <SyncProblemIcon />,
            title: "Team contact-syncing",
            body: "Avoid multiple recruiters sending repeat messages from your automation without first confirring with each other."
        },
        {
            icon: <InsightsIcon />,
            title: "Agency Growth Tracking",
            body: "Track your staffs' network expansion and set the priority for growth in specific areas."
        },
        {
            icon: <InsertLinkIcon />,
            title: "Integration with BullHorn and LinkedIn",
            body: "Connect your agency's network via Bullhorn and LinkedIn to learn the most optimal way to grow."
        },

        {
            icon: <FindInPageIcon />,
            title: "Strip CVs & LinkedIn Profiles",
            body: "Following a typical recruiter workflow, instantly strip all companies that a candidate has worked for and add them to your network."
        },
        {
            icon: <GroupAddIcon />,
            title: "Warm Intros",
            body: "Using your agency's network, immediately see the warm introductions to companies that are hiring within your verical."
        },
        {
            icon: <SavedSearchIcon />,
            title: "Hiring predictions",
            body: "Our AI models can predict when a company is likely to hire, so you can start to build up relationships with that company before anyone else knows."
        },
        {
            icon: <RocketLaunchIcon />,
            title: "Expansion into new verticals",
            body: "Specify the industry that you want to target and start your growth. Our AI models learn the best connections for you to reach out to."
        },
        // {
        //     icon: <AccountTree />,
        //     title: "Feature Title",
        //     body: "Feature Body"
        // },
        // {
        //     icon: <PhoneForwardedIcon />,
        //     title: "In-call Opportunity Spotter",
        //     body: "Our AI joins your google meets calls and, if it spots an opportunity with a company mentioned in the call, makes a note of it in your candidate management system."
        // },
        // {
        //     icon: <AccountTree />,
        //     title: "FeatureTitle",
        //     body: "Feature body Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        // },
    ];

    return (
        <Stack paddingX={{ xs: 2, sm: 4, md: 16 }} paddingY={{xs: 8, sm: 8, md: 16}} spacing={{xs: 4, sm: 4, md: 8}} sx={{ alignItems: 'center' }} ref={sectionRef}>
            <Typography variant="h2">Additional Features</Typography>
            <Grid container columnSpacing={4} rowSpacing={{xs: 4, sm: 4, md: 8}}>
                {features.map((feature, index) => (
                    <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }} key={index}>
                        {cloneElement(feature.icon, { sx: { color: theme.palette.teal.main, fontSize: 48, width: 48, height: 48 } })}
                        <Typography variant="h4">{feature.title}</Typography>
                        <Typography variant="body1">{feature.body}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

export default FullFeatures;