import { Stack, Typography, useTheme } from "@mui/material";

import awsStartupsLogo from '../assets/logos/aws_for_startups.png';
import eagleLabsLogo from '../assets/logos/eagle_labs_logo.png';
import googleLogo from '../assets/logos/google_for_startups_logo_wide.png';
import iukLogo from '../assets/logos/iuk_logo.png';
import natwestLogo from '../assets/logos/natwest_logo.png';
import sterlingRoadLogo from '../assets/logos/sterling_road_logo_dark.png';
import manchesterUniLogo from '../assets/logos/uom_logo.png';


const OurBackers = ({ sectionRef }) => {
    const logoMaxHeight = 96; // Adjust as needed

    return (
        <Stack
            spacing={8}
            paddingX={{ xs: 2, sm: 4, md: 16 }}
            paddingY={8}
            ref={sectionRef}
            marginBottom={16}
        >
            <Stack sx={{ textAlign: 'center', alignItems: 'center' }}>
                <Typography variant="h4" color="primary">And we're already backed by industry leaders</Typography>
                <Typography variant="body1" width={{ xs: "100%", sm: "75%", md: "50%" }}>
                    Our work is made possible through the guidance and support of our incredible partners who share our vision and commitment to innovation.
                </Typography>
            </Stack>
            <Stack spacing={4}>
                <Stack
                    direction="row"
                    spacing={4}
                    sx={{
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                    }}
                >
                    {[
                        { src: natwestLogo, alt: "Natwest Bank Logo", link: "https://www.natwest.com/business/business-services/entrepreneur-accelerator.html" },
                        { src: googleLogo, alt: "Google for Startups Logo", link: "https://startup.google.com/" },
                        { src: awsStartupsLogo, alt: "AWS for startups Logo", link: "https://aws.amazon.com/startups" },
                        { src: eagleLabsLogo, alt: "Barclays Eagle Labs Logo", link: "https://labs.uk.barclays/" },
                        { src: manchesterUniLogo, alt: "The University of Manchester Logo", link: "https://www.entrepreneurship.manchester.ac.uk/" },
                        { src: sterlingRoadLogo, alt: "Sterling Road Investment Bank Logo", link: "https://www.sterlingroad.com/" },
                        { src: iukLogo, alt: "Innovate UK Logo", link: "https://iuk-business-connect.org.uk/business-growth/" },
                    ].map((logo, index) => (
                        <a
                            key={index}
                            href={logo.link}
                            target="_blank"
                            rel="noreferrer"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img
                                src={logo.src}
                                alt={logo.alt}
                                style={{
                                    maxHeight: logoMaxHeight,
                                    maxWidth: '300px',
                                    height: 'auto',
                                    width: 'auto',
                                    objectFit: 'contain',
                                }}
                            />
                        </a>
                    ))}
                </Stack>
            </Stack>
        </Stack>
    );
};

export default OurBackers;
