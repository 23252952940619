import theme from '../theme';
import {
    Box, Divider, Typography,
    useMediaQuery
} from '@mui/material';


import TwoDotLogoPink from '../assets/Two_Dot_pink.png';
import TwoDotLogoWhite from '../assets/Two_Dot_white.png';

const NavBar = ({ logoColour, scrollTargetRef, openBookingDialog }) => {
    // console.log(logoColour)

    const pinTwoDotLogo = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    console.log("pinTwoDotLogo", pinTwoDotLogo)

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,

                // boxShadow: 1,
                // filter: 'blur(8px)',
                padding: '1rem 2rem',
                // background: {xs: '#f00', sm: '#0f0', md: '#00f', lg: '#ff0', xl: '#f0f'},
                background: { xs: '#fff', sm: '#fff', md: '#ffffff00' },
                borderBottom: {xs: '1px solid lightgray', sm: '1px solid lightgray', md: '0px solid white'}
            }}

        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box onClick={() => scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' })}
                    sx={{ cursor: 'pointer' }}>
                    {pinTwoDotLogo ? (
                        <img src={TwoDotLogoPink} height={64} />
                    ) : (
                        logoColour === "clear" ? (
                            <Box sx={{ height: 64 }}></Box>
                        ) : logoColour === "pink" ? (
                            <img src={TwoDotLogoPink} height={64} />
                        ) : logoColour === "white" ? (
                            <img src={TwoDotLogoWhite} height={64} />
                        ) : null
                    )}
                </Box>


                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <a href='/#book-demo' onClick={openBookingDialog}><Typography variant="body2">Request a demo</Typography></a>
                    <a href={`${process.env.REACT_APP_URL}/sign-up`}><Typography variant="body2">Sign up</Typography></a>

                </Box>
            </Box>
        </Box>
            
    )
};

export default NavBar