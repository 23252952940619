import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import backgroundSVG from '../assets/logos/twodot-lander-2.svg';


const HeroSection = ({ scrollTargetRef, sectionRef }) => {
    const theme = useTheme();

    return (
        <Box
            ref={sectionRef}
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                boxSizing: 'border-box',
                paddingX: 4,
                paddingTop: { xs: 16, md: 4, lg: 4 },
                paddingBottom: 2,
                backgroundImage: `url(${backgroundSVG})`, // Set the background image to the imported SVG
                backgroundSize: '100% auto', // Ensures the width spans 100% and height auto-adjusts
                backgroundPosition: {
                    xs: 'center calc(100% - 96px)', // Move the image up by 50px for xs screens
                    sm: 'center calc(100% - 32px)', // Move the image up by 30px for sm screens
                    md: 'bottom center', // Default for larger screens
                },
                backgroundRepeat: 'no-repeat', // Prevent tiling of the image
            }}
        >
            <Stack sx={{ justifyContent: 'center', width: '100%' }}>
                <Typography
                    variant="subtitle1"
                    textAlign={{ xs: 'center', sm: 'center', md: 'left' }}
                    sx={{
                        fontWeight: '400',
                        fontSize: '16px',
                        letterSpacing: '0.1em',
                        width: { sm: "100%", md: '28ch' },
                    }}
                >
                    + Rapidly build deep connections within your recruitment vertical.
                </Typography>

                <Typography
                    variant="subtitle1"
                    textAlign={{ xs: 'center', sm: 'center', md: 'left' }}
                    sx={{
                        fontWeight: '600',
                        fontSize: '16px',
                        width: { sm: "100%", md: '28ch' },
                        marginTop: { sm: 2, md: 0 },
                        cursor: 'pointer',
                    }}
                    onClick={() => scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' })}
                >
                    How?
                </Typography>
            </Stack>

            <Box
                sx={{
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        fontSize: '16px',
                        letterSpacing: '0.1em',
                        textAlign: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={() => scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' })}
                >
                    Scroll ↓
                </Typography>
            </Box>
        </Box>
    )
}

export default HeroSection;